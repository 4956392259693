@import "../styles/ux-variables";

.mdc-button {
  font-weight: $font-weight-bold;
  border-radius: 8px !important;

  &.mdc-button--outlined {
    .mat-mdc-button-persistent-ripple {
      border-color: $primary-color;
      border-width: 2px;
    }

    &.secondary-button {
      .mat-mdc-button-persistent-ripple {
        border-color: $gray-color-300;
        border-width: 1px;
      }
    }
  }
}

form {
  .form-field-with-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;


    &.no-error-field {
      padding-bottom: 10px;
    }

    &.disabled {
      mat-label {
        opacity: 0.4;
      }

    }

    mat-label {
      font-size: $font-small-size;
      font-weight: $font-weight-medium;
      height: 15px;
      padding-bottom: 8px;

      i {
        color: $gray-color-400;
      }
    }

    .mat-mdc-form-field {
      &.small-form {
        .mat-mdc-form-field-infix {
          width: 129px!important;
        }
      }

      &:not(.mat-form-field-disabled) {
        .mdc-notched-outline {
          &.mdc-notched-outline--notched {
            .mdc-notched-outline__leading {
              background: none;
              border: 1px solid #00000026;
              border-right-style: none;
            }

            .mdc-notched-outline__notch {
              background: none;
              border: 1px solid #00000026;
            }

            .mdc-notched-outline__trailing {
              background: none;
              border: 1px solid #00000026;
              border-left-style: none;
            }
          }
        }
      }

      &.mat-form-field-disabled {
        .mat-mdc-form-field-icon-prefix {
          z-index: 99;
        }

        .mdc-notched-outline__leading {
          border-radius: $border-radius 0 0 $border-radius !important;
          background: $gray-color-300;
          border-style: none;
        }

        .mdc-notched-outline__notch {
          background: $gray-color-300;
          border: none;

        }

        .mdc-notched-outline__trailing {
          border-radius: 0 $border-radius $border-radius 0 !important;
          border-style: none;
          background: $gray-color-300;

        }

        .mat-mdc-form-field-infix {
          z-index: 999;
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        height: 0;

        .mat-mdc-form-field-error-wrapper {
          padding: 0;
        }
      }


      .mdc-notched-outline__leading {
        border-radius: $border-radius 0 0 $border-radius !important;
        background: rgb(0 0 0 / 5%);
        border-style: none;
      }

      .mdc-notched-outline__notch {
        background: rgb(0 0 0 / 5%);
        border: none;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 $border-radius $border-radius 0 !important;
        border-style: none;
        background: rgb(0 0 0 / 5%);
      }

      &.mat-focused {
        .mdc-notched-outline__leading {
          background: none;
          border: 1px solid #00000026;
          border-right-style: none;
        }

        .mdc-notched-outline__notch {
          background: none;
          border: 1px solid #00000026;
        }

        .mdc-notched-outline__trailing {
          background: none;
          border: 1px solid #00000026;
          border-left-style: none;
        }
      }

      &.mat-form-field-invalid {
          .mat-mdc-form-field-subscript-wrapper {
            height: 0;
          }

          .mdc-notched-outline__leading {
            border: solid;
            border-width: 1px;
            border-right: none;
            border-color: $wrong-color !important;
          }

          .mdc-notched-outline__notch {
            border: solid;
            border-width: 1px;
            border-left: none;
            border-color: $wrong-color !important;
          }

          .mdc-notched-outline__trailing {
            border: solid;
            border-width: 1px;
            border-left: none;
            border-color: $wrong-color !important;
          }


      }

      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            padding: 11px 0;
            min-height: unset;

            .mat-mdc-input-element {
              height: 18px;
              font-size: $font-regular-size;
            }

          }
        }
      }
    }

    .mat-mdc-form-field-error {
      font-size: 11px;

      span {
        padding-left: 5px;
      }
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-form-field {
    .mdc-switch {
      .mdc-switch__track::after {
        background: $gray-color-200 !important;

      }

      .mdc-switch__handle-track {
        .mdc-switch__icons {
          svg {
            display: none;
          }
        }
      }
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    width: 270px;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab {
            min-width: 30px;
            padding: 0 16px;

            &.mdc-tab--active {
              .mdc-tab-indicator {
                border-top-right-radius: $border-radius;
                border-top-left-radius: $border-radius;
                background: #ECE2FF;

              }
            }

            .mat-ripple {
              border-top-right-radius: $border-radius;
              border-top-left-radius: $border-radius;
            }
          }

          .mdc-tab:hover {
            .mdc-tab__ripple::before {
              border-top-right-radius: $border-radius;
              border-top-left-radius: $border-radius;
            }
          }
        }
      }
    }
  }
}

.buttons-container {
  height: fit-content;

  button + button {
    margin-left: 16px;
  }
}

.input-label {
  padding-bottom: 8px;
}

.mdc-tooltip {
  position: relative;

  .mdc-tooltip__surface {
    border-radius: $border-radius;
    background: $gray-color-900!important;
    max-width: 250px;
  }

  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid $gray-color-900;
  }

  &.below {
    overflow: initial;
    margin-top: 1rem;

    &::after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;

    &::after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    left: 3px;

    &::after {
      left: -0.68rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;

    &::after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}

.date-time {
  .mat-mdc-form-field {
    .mdc-notched-outline__leading {
      border-radius: $border-radius 0 0 $border-radius !important;
      background: rgb(0 0 0 / 5%);
      border-style: none;
    }

    .mdc-notched-outline__notch {
      background: rgb(0 0 0 / 5%);
      border: none;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 $border-radius $border-radius 0 !important;
      border-style: none;
      background: rgb(0 0 0 / 5%);
    }

    &.mat-focused {
      .mdc-notched-outline__leading {
        background: none;
        border: solid #00000026;
        border-right-style: none;
      }

      .mdc-notched-outline__notch {
        background: none;
        border: solid #00000026;
      }

      .mdc-notched-outline__trailing {
        background: none;
        border: solid #00000026;
        border-left-style: none;
      }
    }

    &.mat-form-field-invalid {
      .mdc-notched-outline__leading {
        border: solid $wrong-color;
        border-right-style: none;
      }

      .mdc-notched-outline__notch {
        border: solid $wrong-color;
        border-left-style: none;
      }

      .mdc-notched-outline__trailing {
        border: solid $wrong-color;
        border-left-style: none;
      }
    }
  }

}

.table-wrapper {
  border: 1px solid $gray-color-300;
  border-radius: $border-radius;

  .mat-mdc-table {
    border-radius: $border-radius;

    thead {
      .mat-mdc-header-row {
        opacity: 0.5;

        .mat-mdc-header-cell {
          &.center {
            text-align: center;

            .mat-sort-header-container {
              justify-content: center;
            }
          }
        }
      }
    }

    .mat-mdc-cell {
      &.center {
        text-align: center;

      }
    }
  }

  .cell-image {
    img {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .table-content {
    width: 100%;
    overflow: auto;
    border-radius: $border-radius;
  }

  .mat-mdc-paginator {
    border-radius: $border-radius;

  }
}

.mat-icon {
  &.rollback {
    rotate: 180deg;
    transform: scaleY(-1);
  }

  &.double-copy {
    transform: scaleY(-1);
  }
}

.mat-datepicker-content {
  .mat-calendar {
    .mat-calendar-header {
      .mat-calendar-controls {
        .mat-calendar-previous-button {
          margin-right: 10px;
        }

        .mat-calendar-period-button {
          font-family: Roboto, sans-serif;
          font-weight: $font-weight-regular;
        }

        .mat-calendar-previous-button::after {
          color: black;
        }

        .mat-calendar-next-button::after {
          color: black;
        }
      }

    }

  }
}

.mat-mdc-form-field {
  &.date-time {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          .mat-datepicker-toggle {
            .mdc-icon-button {
              color: $gray-color-800;
            }
          }
        }
      }
    }
  }

}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}


@mixin dialog-box {
  min-width: 300px;
  min-height: 132px;
  width: 560px;
  height: 126px;
  position: static;

  .mat-mdc-dialog-container {
    box-sizing: border-box;
    overflow: inherit;
    border-radius: 8px;

    .mdc-dialog__container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .mat-mdc-dialog-surface {
        overflow-y: unset;
        border-radius: 8px;
      }

      dialog {
        padding: 0;
        width: 100%;
        margin: 0;
        border: none;
        min-height: 40px;
        flex-grow: 1;
        overflow: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: $border-radius;
      }
    }
  }
}

.custom-dialog-box-localization {
  @include dialog-box;

  button {
    width: 200px !important;
  }
}

.custom-dialog-box-streak {
  @include dialog-box;

  button {
    width: auto !important;
    min-width: 100px;
  }
}

.custom-dialog-box {
  @include dialog-box;
}

.mdc-snackbar {
  --mdc-snackbar-container-color: #4A148C !important;

  .mat-button-wrapper {
    color: white;
  }

  &.error-massage {
    --mdc-snackbar-container-color: #E04141 !important;

    button {
      background: #E04141 !important;

      .mdc-button__label {
        color: white;
      }
    }
  }
}

.mat-mdc-select-panel {
  border-radius: $border-radius !important;

  .mat-mdc-option {
    .mdc-list-item__primary-text {
      font-size: $font-regular-size;
      font-weight: $font-weight-regular;
    }

    &.create-new-item {
      .mat-icon {
        margin-right: 12px;
        color: $primary-color-500;
      }
    }

  }
}


.mat-mdc-select-panel-above {
  .mdc-menu-surface {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

  }
}

.event-builder, .correct-answer, .health-result {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        padding: $regular-padding_3;
        border-radius: $border-radius;
      }
    }
  }
}

.edit-input {
  display: flex;
  justify-content: space-between;

  input {
    border: none;
    width: 100%;
    border-bottom: 1px solid black;
    font-size: 15px;
  }
}


.mat-mdc-radio-button {
  --mat-mdc-radio-checked-ripple-color: none !important;
  --mat-radio-ripple-color: transparant !important;
  --mat-radio-checked-ripple-color: transparant !important;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
}

.mat-mdc-slide-toggle:not(.mat-checked, .mat-disabled) .mat-slide-toggle-bar {
  background-color: red; /* set your color here */
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: $gray-color-400 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus, :active) .mdc-switch__handle::after {
  background: $gray-color-400 !important;

}

.chip-form-field {
  .mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      height: 0 !important;
    }
  }
}

.mat-mdc-select {
  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      .mat-mdc-select-placeholder {
        font-size: $font-regular-size !important;
      }

      .mat-mdc-select-value-text {
        .mat-mdc-select-min-line {
          font-size: $font-regular-size !important;
        }
      }
    }
  }
}

.prize-builder {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        background: $gray-color-100;
        overflow: visible;
      }
    }
  }
}

.builder-with-tabs {
  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        overflow: visible;
      }
    }
  }
}


.mat-mdc-paginator {
  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      justify-content: center;
    }
  }

}
