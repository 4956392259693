owl-date-time-timer > owl-date-time-timer-box > button {
  padding: 0;
  min-width: 0;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

owl-date-time-calendar > div.owl-dt-calendar-control > button {
  padding: 0;
  min-width: 0;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

owl-date-time-calendar > div.owl-dt-calendar-control > button:first-child {
  position: absolute;
  right: 3.5em;
}

owl-date-time-timer > owl-date-time-timer-box > label > input {
  border: none !important;
  font-size: 12px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #4a148c;
  color: white !important;
}

owl-date-time-calendar > div.owl-dt-calendar-control > div > button {
  position: absolute;
  left: 0.7em;
  background-color: #f0f0f0 !important;
  padding: 5px;
  border-radius: 5px;
}

div.owl-dt-container-inner > div > button > span {
  display: none !important;
}

div.owl-dt-container-inner > .owl-dt-container-buttons {
    justify-content: flex-end;
    padding-right: 0.4em;
    height: 3em;
    padding-top: unset;
    padding-bottom: 0.5em;


  & > button {
    width: 24% !important;
    border: 1px solid black;
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  & > button::after {
    font-family: 'Material Symbols Outlined', sans-serif;
    font-size: 25px;
    color: black;
  }

  & > button:first-child::after {
    content: '\e5cd';
  }

  & > button:last-child::after {
    content: '\e876';
  }
}

.owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar {
  height: fit-content;
}

.owl-dt-control-arrow-button .owl-dt-control-button-content {
  width: 1.3em;
  height: 1.3em;
}

.owl-dt-timer {
  height: 5.6em;
}

.owl-dt-timer-content {
  margin: unset;
}


.owl-dt-timer-box {
  width: 12%;
}

.owl-dt-timer-divider::before, .owl-dt-timer-divider::after {
  width: 0.15em;
  height: 0.15em;
}

.owl-dt-timer-divider::before {
  top: 45%;
}

.owl-dt-timer-divider::after {
  bottom: 45%;
}

div.owl-dt-container-inner > .owl-dt-container-buttons > button:first-child {
  display: none;
}


.owl-dt-popup-container .owl-dt-timer {
  border-bottom: unset;
}

.owl-dt-control-arrow-button svg {
  width: 60%;
  height: 60%;
}

.owl-dt-calendar-control {
  padding: 0.7em;
}


